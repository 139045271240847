<template>
    <div class="navmb">
		<div class="flex flexa">
            <el-icon color="#FF6A00" size="20" class="astyle bgshadow" @click="$router.back()">
                <Back />
            </el-icon> &nbsp; &nbsp;
			<span class="">账号中心</span>
		</div>
	</div>
    <div class="pad20 bgf mart20">
        <p class="col1582 font16 lh60 borbot">基本信息</p>
        <div class="flex msg">
            <div class="msgleft tc">
                <img width="90" height="90" :src="info.headPic" alt="" srcset="">
                <p @click="cgehead = true; headPic = JSON.parse(JSON.stringify(info.headPic))" class="flex flexa point">
                    <img :src="utils.loadImg('up.png')" alt="" srcset=""> &nbsp;&nbsp;
                    <span class="col7b font10 ">修改头像</span>
                </p>
            </div>
            <ul class="magrig">
                <li>
                    <span>用户名</span>
                    <span>{{ info.username }}</span>
                    <span @click="cgename = true; username = JSON.parse(JSON.stringify(info.username))"><img
                            :src="utils.loadImg('edit.png')" alt="" srcset="">&nbsp;编辑</span>
                </li>
                <li>
                    <span>用户ID</span>
                    <span>{{ info.userNo }}</span>
                </li>
                <li>
                    <span>手机号</span>
                    <span>{{ utils.hidephone(info.mobile) }}</span>
                    <span @click="$router.push({ path: '/cgetel', query: { mobile: info.mobile } })">手机换绑</span>
                </li>
                <li>
                    <span>邮箱号</span>
                    <span>{{ info.email || '- -' }}</span>
                    <span @click="$router.push({
                        name: 'cgeEmail',
                        query: {
                            email: info.email
                        }
                    })">邮箱换绑</span>
                </li>
                <li>
                    <span>地址</span>
                    <span>{{ (info.provinceName||'') + '  ' + (info.cityName||'') + '  ' + (info.districtName||'') + '  ' + (info.address||'- -') }}</span>
                    <span @click="clickEditAddress(info)"><img :src="utils.loadImg('edit.png')" alt=""
                            srcset="">&nbsp;编辑</span>
                </li>
                <li>
                    <span>登录密码</span>
                    <span>*******</span>
                    <span
                        @click="$router.push({ path: '/cgemima', query: { mobile: info.mobile, email: info.email } })">修改密码</span>
                </li>
            </ul>
        </div>
    </div>
    <p class="h20"></p>
    <div class="pad20 bgf">
        <!-- <p class="col1582 font16 lh60 borbot">CSI号信息及所属公司等信息</p> -->
        <div class="padt20 padb30">
            <el-button @click="bangding = true; csiNumber = '';" type="primary" class="w175">新增绑定CSI号</el-button>
            <p class="h20"></p>
            <el-table :data="bindingCSIList" :header-cell-style="{ background: '#F1F1F1', }" class="border"
                style="width: 100%">
                <el-table-column fixed prop="csiNumber" label="CSI" min-width="50">
                </el-table-column>
                <el-table-column prop="companyName" label="公司名称" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column
                prop="productName"
                label="采购产品"
                show-overflow-tooltip
                >
                </el-table-column> -->
                <el-table-column label="身份" min-width="100">
                    <template #default="scope">
                        {{ { 1: '普通用户', 2: '管理员' }[scope.row.identity] }}
                    </template>
                </el-table-column>

                <el-table-column label="状态" min-width="120">
                    <template #default="scope">
                        <span class="colblue ">
                            {{ { 1: '等待管理员审核', 2: '审核成功', 3: '审核失败' }[scope.row.status] }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                    <template #default="scope">
                        <span @click="$router.push('/csiInfo/' + scope.row.id + ',' + scope.row.identity + ',' + scope.row.csiId)"
                            class="colblue point">详情</span>
                        <span v-if="scope.row.status == 2">
                            <el-divider direction="vertical"></el-divider>
                            <span @click="sciNum = scope.row.id; jiebang = true;" class="colblue point">解除绑定</span>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
    <p class="h20"></p>
    <div class="flex last">
        <dl class="lastleft padb30">
            <dt class="flexab">
                <span>收藏夹</span>
                <img @click="cgeclect = true" :src="utils.loadImg('edit2.png')" alt="" class="point" srcset="">
            </dt>
            <dd v-for="(item, index) in favoritesL" :key="index" class="text1"
                @click="getColL(item.idstring, item.title)">
                <img :src="utils.loadImg('file.png')" alt="" srcset=""> &nbsp;&nbsp;
                <span class="col3 font14 ">{{ item.title }}（{{ item.number }}）</span>
            </dd>

        </dl>
        <dl class="lastrig">
            <dt class="col1582 font16 lh60 borbot">{{ idxTxt }}（{{ total }}）</dt>
            <dd v-for="(item, index) in lists" :key="index">
                <div @click="$router.push('/knowledgeInfo/' + item.docid)" class="padr20">
                    <p class="col1582 font18 padb14">{{ item.docTitle }} </p>
                    <div class="text2 font14 lh28 col68">
                        {{ utils.markdonTxt(item.docProfile) }}
                    </div>
                    <p class="col9c padt10">来自：{{ item.categoryName }} </p>
                </div>
                <div v-if="!item.requestCollect" class="colec" @click="clickSc(item)">
                    <p>已收藏</p>
                </div>
                <div v-else class="coleun">
                    <p>已取消收藏</p>
                </div>
            </dd>
            <div class="tc padt40 padb40" v-if="total > 0">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="param.pageNumber" :page-sizes="[5, 10, 15, 20]" :page-size="param.pageSize"
                    layout=" prev, pager, next, sizes, jumper" :total="total">
                </el-pagination>
            </div>
        </dl>
    </div>

    <el-dialog :close-on-click-modal="false" title="新增绑定CSI号" width="490px" v-model="bangding">
        <div class="padl10">
            <el-input v-model="csiNumber" placeholder="请输入客户服务号（CSI）" class="w350">
            </el-input>
            <p class="w350 font10 colb2 lh20 padt10">申请绑定客户服务号 需要等待公司的管理员(CAU)审核，审核通过后
                即可绑定成功。只有关联了CSI号且CSI在有效期内的账户才能提交对应的技术工单</p>
        </div>

        <template #footer>
            <div class="tl padl10">
                <el-button size="small" type="primary" @click="bindcsi">申请绑定</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="修改头像" width="530px" v-model="cgehead">
        <div class="" v-loading="loading">
            <div class="positionr inbl">
                <el-button type="primary" @click="cgehead = false">选择图片</el-button>
                <input type="file" accept="image/jpeg,image/jpg,image/png" @change="handleFile('image2')" id="image2"
                    class="hiddenInput" />
            </div>
            <p class="h20"></p>
            <img :src="headPic" width="223" alt="">
        </div>
        <template #footer>
            <div class="tl">
                <el-button size="small" @click="cgehead = false" class="w68">取消</el-button>
                <el-button size="small" type="primary" @click="tocgehead" class="w68">确定</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="修改用户名" width="490px" v-model="cgename">
        <div class="">
            <el-input v-model="username" placeholder="请输入用户名" class="w350">
            </el-input>
            <p class="w350 font10 colb2 lh20 padt10">用户名支持数字、字母、文字等多种格式</p>
        </div>
        <template #footer>
            <span class="dialog-footer ">
                <el-button size="small" type="primary" @click="tocgename">确认修改</el-button>
            </span>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="编辑收藏夹" width="382px" v-model="cgeclect">
        <ul class="ul1">
            <el-scrollbar>
                <li class="" v-for="(item, index) in colecList" :key="index">
                    <div class="flex flexa" :id="'msg' + index">
                        <p class="col3 font14 padr14">{{ item.title }}</p>
                        <img @click="bianji(index, item.title)" :src="utils.loadImg('edit2.png')" class="marr10 point"
                            alt="">
                        <img @click="delclect(item.idstring)" :src="utils.loadImg('del2.png')" class="point" alt="">
                    </div>
                    <div class="none flex flexa" :id="'inp' + index">
                        <el-input v-model="item.title" class="inpw" placeholder="请输入内容"></el-input>
                        <img @click="tobianji(index, item)" :src="utils.loadImg('dui.png')" class="marr10 point" alt="">
                        <img @click="cancel(index)" :src="utils.loadImg('del3.png')" class="point" alt="">
                    </div>
                </li>
            </el-scrollbar>
        </ul>
        <div class="none flex flexa h50" id="add">
            <el-input v-model="addvalue" class="inpw" placeholder="请输入内容"></el-input>
            <img @click="toadd(1)" :src="utils.loadImg('dui.png')" class="marr10 point" alt="">
            <img @click="toadd(0)" :src="utils.loadImg('del3.png')" class="point" alt="">
        </div>
        <div @click="addclect" class="flex flexa colzhuti font12 padt20 point">
            <img :src="utils.loadImg('add.png')" width="16" alt=""> &nbsp;&nbsp;
            添加分类项
        </div>
        <template #footer>
            <div class="tl ">
                <el-button size="small" type="primary" @click="cgeclect = false" class="w110">确 定</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="您确定要解除当前绑定的CSI账号么？" width="490px" v-model="jiebang">
        <div class="padl10">
            <p class="col65">解除绑定后 则不能提交该CSI号类型下的技术工单</p>
        </div>
        <template #footer>
            <div class="tl padl10 padb10">
                <el-button size="small" @click="jiebang = false" class="w68">取 消</el-button>
                <el-button size="small" type="primary" @click="unbindCsi" class="w68">确 定</el-button>
            </div>
        </template>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="修改地址" width="490px" v-model="addressData.show">
        <div class="flex">
            <div class="ll lh30">选择省：</div>
            <div class="rr marl10">
                <el-select @change="changeProvince" v-model="addressData.form.provinceId" class="w242" placeholder="选择省" clearable>
                    <el-option v-for="item in addressData.provinceL" :key="item.id" :label="item.name"
                        :value="item.id" />
                </el-select>
            </div>
        </div>
        <div class="flex mart20">
            <div class="ll lh30">选择市：</div>
            <div class="rr marl10">
                <el-select @change="changeCity" v-model="addressData.form.cityId" class="w242" placeholder="选择市" clearable>
                    <el-option v-for="item in addressData.cityL" :key="item.id" :label="item.name"
                        :value="item.id" />
                </el-select>
            </div>
        </div>
        <div class="flex mart20">
            <div class="ll lh30">选择区：</div>
            <div class="rr marl10">
                <el-select v-model="addressData.form.districtId" class="w242" placeholder="选择区" clearable>
                    <el-option v-for="item in addressData.districtL" :key="item.id" :label="item.name"
                        :value="item.id" />
                </el-select>
            </div>
        </div>
        <div class="flex mart20">
            <div class="ll lh30">详细地址：</div>
            <div class="rr marl10">
                <el-input v-model="addressData.form.address" placeholder="请输入详细地址" class="w350"></el-input>
            </div>
        </div>

        <template #footer>
            <div class="tl padl10">
                <el-button size="small" type="primary" @click="clickAddress">确定</el-button>
            </div>
        </template>
    </el-dialog>

</template>
<script>
import { Back } from '@element-plus/icons-vue'
import { userCollectionCategory, userCompylist, accountBaseInfo, editInfo, removeFavorites, addBindingCsi, accountFavorites, addFavorites } from "@/utils/api1.js"
// import { customerServiceNo} from '@/utils/api2.js'
import areaData from "@/js/city.js"
export default {
    components: {
        Back,
    },
    data() {
        return {
            collectionCategoryId:'',//收藏夹id
            areaData,
            sciNum: '',
            jiebang: false,
            loading: false,
            bangding: false, //新增绑定CSI号
            cgehead: false, //修改头像
            cgename: false, //修改用户名
            cgeclect: false,  //编辑收藏夹
            headpic: '',
            username: '',
            info: {
                mobile: '',
            },
            bindingCSIList: [],//CSI列表
            addvalue: '', //新增收藏夹名称
            colecList: [],
            colecname: '',
            csiNumber: '',  //新增绑定CSI号数据
            param: {
                pageNumber: 1,
                pageSize: 5,
            },
            list: [],
            lists: [],
            total: 0,
            idxTxt: '',
            favoritesL: [],
            addressData: {
                show: false,
                form: {
                    cityId: '',
                    districtId: '',
                    provinceId: '',
                },
                provinceL:areaData, cityL: [], districtL: [],
            }
        }
    },
    mounted() {
        console.log('this.$route.query',this.$route.query.type)
        this.getData();
        this.getBindingCSIL()
        this.getFavoritesL(true)
        if(this.$route.query.type) this.cgeclect = true
        // this.getfavlist();
        // this.getColL('', '默认收藏夹')
    },
    methods: {
        async changeEmail() {
            try {
                await this.$msgbox.prompt('请输入邮箱', '邮箱', {
                    inputPlaceholder: '请输入邮箱',
                    confirmButtonText: '确认',
                    inputValue: this.info.email,
                    inputPattern: new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"),
                    inputErrorMessage: '邮箱格式有误',
                    beforeClose: async (action, instance, done) => {
                        try {
                            if (action === 'confirm') {
                                instance.confirmButtonLoading = true
                                instance.confirmButtonText = '处理中…'
                                this.info.emailLoading = true
                                const result = await editInfo({
                                    email: instance.inputValue,
                                })
                                if (result) {
                                    done()
                                    this.$message.success('修改成功')
                                    this.getData()
                                }
                            } else {
                                throw new Error()
                            }
                        } catch (e) {
                            console.log(e)
                            return done()
                        }
                    },
                })
            } catch (e) {
                return console.log(e)
            } finally {
                this.info.emailLoading = false
            }
        },
        async clickSc(item){
            console.log(item, 'item')
            const res = await this.$api.document.insertCollectCategory({
                docId: item.docid,collectionCategoryId: this.collectionCategoryId
            })
            if(res){
                item.requestCollect = true
                // this.getColL(this.collectionCategoryId, this.idxTxt)
                this.getFavoritesL(false)
                this.$message.success(res.message)
            }
        },
        clickEditAddress(info) {
            let that = this
            console.log('详情',info)
            that.addressData = {
                show: true,
                form: {
                    cityId: info.cityId,
                    districtId: info.districtId,
                    provinceId: info.provinceId,
                    address: info.address,
                },
                provinceL:areaData, cityL: [], districtL: [],
            }
            if (info.provinceId) {
                areaData.map(val => {
                    if (val.id == info.provinceId) {
                        that.addressData.cityL = val.cities
                    }
                })
            }
            if (info.cityId) {
                areaData.map(a => {
                    a.cities.map(b => {
                        if (info.cityId == b.id) {
                            that.addressData.districtL = b.counties
                        }
                    })
                })
            }
            console.log('areaData', that.addressData)
        },
        changeProvince(e){
            let that = this
            areaData.map(val=>{
                if(val.id==e){
                    that.addressData.cityL = val.cities
                    that.addressData.districtL = []
                    that.addressData.form.cityId = ''
                    that.addressData.form.districtId = ''
                }
            })
        },
        changeCity(e){
            let that = this
            that.addressData.cityL.map(val=>{
                if(val.id==e){
                    that.addressData.districtL = val.counties
                    that.addressData.form.districtId = ''
                }
            })
        },
        clickAddress(){
            let that= this
            console.log(that.addressData)
            editInfo(that.addressData.form).then(res => {
                if (res) {
                    that.getData()
                    that.addressData = {
                        show: false,
                        form: {cityId: '',districtId: '',provinceId: ''},
                        provinceL:areaData, cityL: [], districtL: [],
                    }
                    that.$message.success(res.message)
                }
            })
        },
        getFavoritesL(type) {
            let that = this
            userCollectionCategory().then(res => {
                console.log('收藏夹列表', res)
                if (res) {
                    that.favoritesL = JSON.parse(JSON.stringify(res))
                    that.colecList = JSON.parse(JSON.stringify(res))
                    if (res.length > 0&&type) {
                        that.getColL(res[0].idstring, res[0].title)//打开第一个文件夹
                    }

                }
            })
        },
        getBindingCSIL() {
            let that = this
            userCompylist().then(res => {
                console.log('identity', res)
                if (res) {
                    that.bindingCSIList = res
                }
            })
        },
        

        async unbindCsi() {
            try {
                const result = await this.$api.user.Unbindbangding({
                    id: this.sciNum,
                })
                if (result) {
                    this.$message.success(result.message);
                    this.jiebang = false
                    this.getBindingCSIL()
                }
            } catch (e) {
                return console.log(e)
            }
        },

        getColL(id, txt) {
            console.log(id);
            this.collectionCategoryId = id
            this.idxTxt = txt;
            if (id) {
                this.param.collectionCategoryId = id;
            } else {
                this.param.collectionCategoryId = '';
            };
            console.log(this.param);
            accountFavorites(this.param).then(res => {
                console.log(res);
                if (res) {
                    this.lists = res.records
                    this.total = res.total;
                }
            })
        },

        // 绑定CSI
        bindcsi() {
            this.bangding = false;
            addBindingCsi({ csiNumber: this.csiNumber }).then(res => {
                console.log(res);
                if (res) {
                    this.$message.success(res.message);
                    this.getBindingCSIL();
                }
            });
        },

        getData() {
            accountBaseInfo().then(res => {
                if (res) {
                    this.info = res;
                }
            });
        },

        // 修改姓名
        tocgename() {
            this.cgename = false;
            editInfo({ username: this.username }).then(res => {
                console.log(res);
                if (res) {
                    location.reload()
                    this.$message.success('用户名修改成功！')
                }
            })
        },

        // 修改头像
        tocgehead() {
            this.cgehead = false;
            editInfo({ headPic: this.headPic }).then(res => {
                console.log(res);
                if (res) {
                    location.reload()
                    this.$message.success('头像修改成功！')
                }
            })
        },

        //  上传图片
        handleFile(id) {
            this.loading = true;
            let imgFile = document.getElementById(id).files[0];
            var URL = window.URL || window.webkitURL;
            let form = new FormData();
            form.append('multipartFile', imgFile, imgFile.name);
            form.append('type', 2)
            this.$api.base.ossUploadList(form).then(res => {
                console.log(res);
                this.loading = false;
                if (res) {
                    this.headPic = res.path;
                }
            })
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },

        bianji(idx, name) {
            this.colecname = name;
            let ms = document.getElementById('msg' + idx);
            let inp = document.getElementById('inp' + idx);
            inp.classList.remove('none');
            ms.classList.add('none')
        },
        tobianji(idx, val) {
            let ms = document.getElementById('msg' + idx);
            let inp = document.getElementById('inp' + idx);
            inp.classList.add('none');
            ms.classList.remove('none');

            addFavorites({ title: val.title, id: val.idstring }).then(res => {
                console.log(res);
                if (res) {
                    this.$message.success("修改成功！");
                    this.getFavoritesL(true)

                } else {
                    this.getFavoritesL(true)
                }
            })
        },

        // 取消编辑
        cancel(idx) {
            let ms = document.getElementById('msg' + idx);
            let inp = document.getElementById('inp' + idx);
            inp.classList.add('none');
            ms.classList.remove('none');
            this.colecList[idx].title = this.colecname;
        },

        delclect(id) {
            this.$confirm('您确认要删除该类别及以下的所有内容么，一旦删除无法恢复', '确定要删除该收藏夹么？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                removeFavorites({ id }).then(res => {
                    console.log(res);
                    if (res) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getFavoritesL(true);
                    }
                })

            }).catch(() => {

            });
        },

        addclect() {
            let ad = document.getElementById('add');
            ad.classList.remove('none')
        },
        toadd(num) {
            if (num == 1) {
                addFavorites({ title: this.addvalue }).then(res => {
                    console.log(res);
                    if (res) {
                        this.addvalue = ''
                        this.$message.success("添加成功！");
                        this.getFavoritesL(true);
                        this.getData();
                    }
                })
            };
            let ad = document.getElementById('add');
            ad.classList.add('none')
        },
    }
}
</script>
<style lang="scss" scoped>
.ul1 {
    height: 300px;
    overflow: hidden;

    li {
        height: 50px;

        >div {
            height: 50px;
        }
    }
}

.none {
    display: none;
}

.inpw {
    max-width: 130px;
    margin-right: 14px;
}

.w350 {
    width: 350px;
}

.lastrig {
    width: 100%;
    padding: 0 20px;
    background: #fff;

    dd {
        padding: 28px 0;
        border-bottom: 1px dashed #E6E6E6;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        .colec {
            width: 160px;
            min-width: 160px;
            border-left: 1px dashed #E6E6E6;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: #EBF7ED;
                color: #47B156;
                font-size: 14px;
                text-align: center;
                line-height: 80px;
            }
        }
        .coleun {
            width: 160px;
            min-width: 160px;
            border-left: 1px dashed #E6E6E6;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: #F1F1F1;
                color: #666666;
                font-size: 14px;
                text-align: center;
                line-height: 80px;
            }
        }
    }
}

.lastleft {
    width: 240px;
    min-width: 240px;
    border: 1px solid #E9E9E9;
    background: #fff;
    margin-right: 20px;

    dt,
    dd {
        padding: 0 20px;
    }

    dt {
        height: 44px;
        background: #E9E9E9;
    }

    dd {
        display: flex;
        align-items: center;
        margin: 3px 0;
        height: 32px;
        cursor: pointer;
    }

    dd:hover {
        background: #FFE9D9;
    }
}

.msg {
    padding: 30px 0;

    .msgleft {
        width: 186px;

        >img {
            border-radius: 50%;
        }

        >p {
            width: 100px;
            height: 26px;
            background: #F0F0F0;
            justify-content: center;
            border-radius: 20px;
            margin: 24px auto 0;
        }
    }

    .magrig {
        white-space: nowrap;

        li {
            span {
                display: inline-block;
                line-height: 30px;
            }

            >span:nth-child(1) {
                color: #7B7B7B;
                width: 72px;
                min-width: 72px;
            }

            >span:nth-child(2) {
                color: #343434;
                min-width: 156px;
            }

            >span:nth-child(3) {
                margin-left:20px;
                color: #FF6A00;
                cursor: pointer;
            }
        }
    }
}

.hiddenInput {
    opacity: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
</style>